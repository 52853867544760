import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import LogoWhite from "../../assets/svgs/logo-light.svg"
import Frame from "../../components/common/frame"
import "../../styles/pages/wall-of-love.scss"

const EarlyAccess = () => {
  var urlToShare = encodeURIComponent("https://superops.com")

  return (
    <div className="wall-of-love">
      <>
        <Frame IsFooterVisble="No" bodyClassName="p-0">
          <section className="wall-bg">
            <Container className="Layout-container">
              <Row className="justify-content-center">
                <Col lg={11} className="right mb100 mt100">
                  <Col lg={8} className="mx-auto text-center">
                    <div className="thankyou-svg mx-auto down-to-up-1">
                      <img
                        src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/d1mJBGoJQ7KYm2iFIfIy"
                        className="w-100"
                      />
                    </div>

                    <h3 className="font-roboto fw-bolder content mt32 mb24 down-to-up-2">
                      Share your love for SuperOps on your favorite social
                      platform!
                    </h3>
                    <div className="p24 mb48 down-to-up-3">
                      <p className="mb0">
                        Don't forget to upload a pic of the gift 😉{" "}
                      </p>
                    </div>

                    <div className="Social mx-auto mb48 down-to-up-4">
                      <div className="d-flex gap-24 justify-content-center flex-wrap">
                        <a
                          href="https://twitter.com/intent/tweet?hashtags=SuperOpsThanksYou,WeLoveSuperOps&amp;original_referer=https%3A%2F%2Fwww.superops.ai%2F&amp;text=Look%20what%20I%20got%20from%20SuperOps.ai%20today!"
                          className="single-share"
                        >
                          <div>
                            <img
                              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/6U00up0DT5mAiy2mKVDy"
                              className="w-100"
                            />
                          </div>
                        </a>
                        <a
                          href={"https://www.linkedin.com/"}
                          className="single-share"
                        >
                          {" "}
                          <div>
                            <img
                              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/zsv5ThzKTPKSXGqtBzQE"
                              className="w-100"
                            />
                          </div>
                        </a>
                        <a
                          href={"https://www.facebook.com/"}
                          className="single-share"
                        >
                          {" "}
                          <div>
                            <img
                              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/xAP074FnQqCFLIevk5Zf"
                              className="w-100"
                            />
                          </div>
                        </a>
                        <a
                          href={"https://www.instagram.com/create/story"}
                          className="single-share"
                        >
                          {" "}
                          <div>
                            <img
                              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/rfiQdNwRfaiJsNwXPCp4"
                              className="w-100"
                            />
                          </div>
                        </a>
                      </div>
                    </div>

                    <Col lg={9} className="mx-auto p20 mb90 down-to-up-5">
                      <p className="mb0">
                        Please use the hashtags{" "}
                        <span className="text-pink">#SuperOpsThanksYou</span>
                      </p>
                      <p className="mb0">
                        <span className="text-pink">#WeLoveSuperOps</span> when
                        sharing.
                      </p>
                    </Col>

                    <Col lg={3} className="mx-auto down-to-up-5">
                      <LogoWhite className="sopslogo" />
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Container>
          </section>
        </Frame>
      </>
    </div>
  )
}

export default EarlyAccess
